import * as React from 'react';
import styled from 'styled-components';

import {
  Ul,
  Bullet,
  Hr,
  Titles,
  TitlesWrapper,
  Content,
  Li,
} from './ContentStyles';

export const Description = styled.div`
font-size: 15px;
font-weight: 500;
`;
export const Company = styled.div`
font-size: 13px;
font-weight: none;
`;

export const Volunteering = () => {
  return (
    <>
      <Titles md={3}>
        <TitlesWrapper>
          <h2 style={{textDecoration: 'underline'}}>Volunteering</h2>
        </TitlesWrapper>
        <Hr />
      </Titles>
      <Content md={6}>
        <Ul>
          <Li>
            <Bullet>Sep 2024 ⭐</Bullet>
          <div>
            <Company>
              Leading and taking part of development process for a research app</Company>
              <Description>OpenHeartMind.org</Description>
              </div>
          </Li>
          <Li>
            <Bullet>Jan 2019 ⭐</Bullet>
            <div>
            <Company>
              Hosting meetup evening events in Melbourne to learn & network</Company>
              <Description>FreeCodeCamp.org</Description>
              </div>
          </Li>
          <Li>
            <Bullet>Jan 2018 ⭐</Bullet>
            <div>
            <Company>Supporting kids learning how to code</Company>
            <Description>Coder Dojo, Richmond</Description>
            </div>
          </Li>
        </Ul>
      </Content>
    </>
  );
};
